import Vue from "vue";
import Router from "vue-router";
import axios from "axios";
var consts = require("./config");
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
    },
    {
      path: "/finish",
      name: "finish",
      component: () => import("./views/Finish.vue"),
    },
    {
      path: "/:_id",
      name: "Home",
      component: () => import("./views/Home.vue"),
    },
    {
      path: "/wait",
      name: "waiting",
      component: () => import("./views/Wait.vue"),
    },
    {
      path: "/status/:_id/:_secu",
      name: "Status",
      component: () => import("./views/Status.vue"),
    },
    {
      path: "/update/:_id/:_secu",
      name: "Update",
      component: () => import("./views/Update.vue"),
    },

  ],
});

export default router;
